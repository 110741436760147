import React from "react";

const PopUp = (props) => {
  return (
    <div className={"modal " + (props.display ? "open" : "closed")}>
      <div className="modal_content">
        <div className="title">
          <div className="title-text">Product Overview</div>
          <div onClick={props.closeAction} className="close icon icon-x" />
        </div>
        <props.contents/>
      </div>
    </div>
  );
};

export { PopUp };
