import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { findRouteByRoles } from './Roles';

interface ProtectedRouteProps {
    children: JSX.Element | JSX.Element[];
    path: string;
    exact: boolean;
    roles: string[];
};

const ProtectedRoute = ({ children, path, exact, roles }: ProtectedRouteProps) => {
    // If in the users roles we find...
    const isAllowed = findRouteByRoles(roles, path) !== undefined;
    return (
        <Route path={path} exact={exact} render={() => {
            if (isAllowed) {
                return <>{children}</>;
            } else {
                return <Redirect to='/' />
            }
        }
        } />
    )
}

export {
    ProtectedRoute
};