import React from "react";

interface LinksProps {
  children: JSX.Element[];
};

const Links = ({ children }: LinksProps) => {
  return children && children.length > 0 ?
    <div className='links'>
      {children}
    </div>
    : <></>
};

export {
  Links
};