import React from "react";
import { Link } from "react-router-dom";
import { Routes } from "../../routing/Routes";

const GetStarted = () => {
  return (
    <div className="sub-content get-started">
      <div className="text-doc">
        <p className="title">
          Welcome to Fugro ROAMES Virtual World Asset Management.
        </p>
        <p>Learn the basics here so you can get started quickly.</p>
        <p>
          The ROAMES App Hub is a website that gives you access to our new
          generation of applications: ROAMES World and ROAMES Analytics. The App
          Hub is also the best location for ROAMES users to find support
          documentation, product information, and news.
        </p>
        <p>
          Product Quick Start guides are an excellent way to become familiar
          with each application. The guide introduces basic concepts and how to
          access standard functionality.
        </p>
        <p>The following Quick Start guides are available now:</p>
        <div className="quickStartLink">
          <i className="icon icon-Icon_Analytics" />
          <Link to={Routes.SUPPORT_GETSTARTED_ANALYTICS}>
            ROAMES Analytics Quick Start Guide
          </Link>
        </div>
        <div className="quickStartLink">
          <i className="icon icon-Icon_RW" />
          <Link to={Routes.SUPPORT_GETSTARTED_WORLD}>
            ROAMES World Quick Start Guide
          </Link>
        </div>
        <p>
          ROAMES applications can be accessed via the{" "}
          <Link to={Routes.APPS}>Applications menu</Link> in the ROAMES App Hub
          or via direct link in your internet browser. ROAMES applications are
          integrated and offer the most value when used in tandem.
        </p>
        <p>
          We advise opening ROAMES Analytics first, where you can explore your
          data for records of interest or prioritize based on risk. Then
          validate your findings in ROAMES World – just click the ROAMES World
          icon in the lower left corner of the table window to launch the map
          view.
        </p>
        <p>
          Fugro ROAMES is a rich set of information and tooling. The{" "}
          <Link to={Routes.SUPPORT}>Support menu</Link> offers a glossary to
          clarify terminology and a link to the Help Centre where you can file a
          support ticket.
        </p>
        <p>
          Fugro is planning further development of the current ROAMES products
          as well as new products and features that will help you manage your
          power networks more effectively. Stay up to date by checking the
          ROAMES App Hub <Link to={Routes.ROOT}>Home page</Link> and{" "}
          <Link to={Routes.NEWS}>News menu</Link>.
        </p>
      </div>
    </div>
  );
};

export { GetStarted };
