import React from "react";
import { Link } from 'react-router-dom';
import { Routes } from "../routing/Routes";

const NotFound = () => {
  return <div className='app-content page-not-found'>
    <h1 className='title'>
      <i className='icon-error'/>
      404
    </h1>
    <h2 className='text subtitle'>
      This page doesn't exist.
    </h2>
    <div className='text description'>
      <p>We're sorry, the page you requested could not be found.</p>
      <p>Please <Link to={Routes.ROOT}>return to the homepage</Link> or contact us at <a href="mailto:support@roames.com.au">support@roames.com.au</a></p>
    </div>
  </div>;
}

export {
  NotFound
};
