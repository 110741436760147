import React from "react";
import { Route } from "react-router-dom";
import QSGRW_Logo from '../../../../../img/quickstart/QSGRW_Logo.png';
import QSGRW_Main_Meu from '../../../../../img/quickstart/QSGRW_Main_Meu.png';
import QSGRW_Icon_RW_Cycle from '../../../../../img/quickstart/QSGRW_Icon_RW_Cycle.png';
import QSGRW_Icon_Layers from '../../../../../img/quickstart/QSGRW_Icon_Layers.png';
import QSGRW_Icon_Local_Layers from '../../../../../img/quickstart/QSGRW_Icon_Local_Layers.png';
import QSGRW_Icon_Tool from '../../../../../img/quickstart/QSGRW_Icon_Tool.png';
import QSGRW_Icon_Setting from '../../../../../img/quickstart/QSGRW_Icon_Setting.png';
import QSGRW_Imagery_Camera from '../../../../../img/quickstart/QSGRW_Imagery_Camera.png';
import QSGRW_Imagery_Street from '../../../../../img/quickstart/QSGRW_Imagery_Street.png';
import QSGRW_Imagery_Satellite from '../../../../../img/quickstart/QSGRW_Imagery_Satellite.png';
import QSGRW_Imagery_MaxHeight from '../../../../../img/quickstart/QSGRW_Imagery_MaxHeight.png';
import QSGRW_Imagery_GreyScale from '../../../../../img/quickstart/QSGRW_Imagery_GreyScale.png';
import QSGRW_Cycle from '../../../../../img/quickstart/QSGRW_Cycle.png';
import QSGRW_Draw_Tool from '../../../../../img/quickstart/QSGRW_Draw_Tool.png';
import QSGRW_Tools_Extract from '../../../../../img/quickstart/QSGRW_Tools_Extract.png';
import QSGRW_Tool_Measure from '../../../../../img/quickstart/QSGRW_Tool_Measure.png';
import QSGRW_Local_Layer02 from '../../../../../img/quickstart/QSGRW_Local_Layer02.png';
import QSGRW_Local_Layer01 from '../../../../../img/quickstart/QSGRW_Local_Layer01.png';
import QSGRW_Full_Screen from '../../../../../img/quickstart/QSGRW_Full_Screen.png';
import QSGRW_Icon_Fugro from '../../../../../img/quickstart/QSGRW_Icon_Fugro.png';
import QSGRW_Screenshot from '../../../../../img/quickstart/QSGRW_Screenshot.png';
import QSGRW_Path_Long from '../../../../../img/quickstart/QSGRW_Path_Long.png';
import QSGRW_Path_Short from '../../../../../img/quickstart/QSGRW_Path_Short.png';
import QSGRW_Quick_Layer_Add from '../../../../../img/quickstart/QSGRW_Quick_Layer_Add.png';
import QSGRW_Quick_Layer_LayerOff from '../../../../../img/quickstart/QSGRW_Quick_Layer_LayerOff.png';
import QSGRW_Quick_Layer_Undo from '../../../../../img/quickstart/QSGRW_Quick_Layer_Undo.png';
import QSGRW_PC_Colour from '../../../../../img/quickstart/QSGRW_PC_Colour.png';
import QSGRW_PC_Height from '../../../../../img/quickstart/QSGRW_PC_Height.png';
import QSGRW_PC_Classified from '../../../../../img/quickstart/QSGRW_PC_Classified.png';
import QSGRW_Search_Type from '../../../../../img/quickstart/QSGRW_Search_Type.png';
import QSGRW_Search from '../../../../../img/quickstart/QSGRW_Search.png';
import QSGA_Icon_RW_connected from '../../../../../img/quickstart/QSGA_Icon_RW_connected.png';
import QSGA_Icon_RW_Navi_Enable from '../../../../../img/quickstart/QSGA_Icon_RW_Navi_Enable.png';
import QSGRW_RWConnected_PopUp from '../../../../../img/quickstart/QSGRW_RW-Connected_PopUp.png';
import QSGRW_Icon_UploadKML from '../../../../../img/quickstart/QSGRW_Icon_UploadKML.png';
import QSGRW_Icon_Create_Folder from '../../../../../img/quickstart/QSGRW_Icon_Create_Folder.png';
import QSGRW_Icon_Download from '../../../../../img/quickstart/QSGRW_Icon_Download.png';
import QSGRW_Icon_Edit from '../../../../../img/quickstart/QSGRW_Icon_Edit.png';
import QSGRW_Icon_Remove from '../../../../../img/quickstart/QSGRW_Icon_Remove.png';
import QSGRW_Icon_AreaPolygon from '../../../../../img/quickstart/QSGRW_Icon_AreaPolygon.png';
import QSGRW_Icon_DistancePath from '../../../../../img/quickstart/QSGRW_Icon_DistancePath.png';
import QSGRW_Icon_DistanceTwoPoints from '../../../../../img/quickstart/QSGRW_Icon_DistanceTwoPoints.png';

const QuickStartGuideForRoamesWorld = () => {
  return (
    <Route path="/support/get-started/world">
      <div className="sub-content quickstart">
        <div className='text-doc'>
          <img src={QSGRW_Logo} alt='FUGRO ROAMES WORLD' style={{width: 397}}/>
          <h3>Quick Start Guide</h3>
          <p>The basics on how to use ROAMES World effectively.</p>
          <br/>
          <h3>Getting Help</h3>
          <p>Help information can be obtained via the Support menu in the App Hub. It is strongly recommended that the Get Started information is read by any new user to the latest Fugro ROAMES products.</p>
          <hr/>
          <h3>Accessing Menus</h3>
          <p>The main menus can be access by clicking on the icons on the left hand bar:</p>
          <br/>
          <div className='sideways'>
            <img src={QSGRW_Main_Meu} alt='ROAMES World Main Menu' />
            <div>
              <p>&lt;left mouse click&gt; on any of the main icons on the left hand side (see image on the left) will reveal the options available. The active menu will turn orange. &lt;left mouse click&gt; on the active menu will close the menus.</p>
              <br/>
              <p>The Following menus are available:</p>
              <br/>
              <div className='sideways'>
                <div></div>
                <img className='imageicon' src={QSGRW_Icon_RW_Cycle} alt='Select Active Dataset Icon' />
                <p><strong>Select Active Dataset</strong>: Allows choice of cycle that has been captured and hence access to the appropriate point cloud and imagery (does not affect the 3D Asset and Vegetation Models).</p>
              </div>
              <br/>
              <div className='sideways'>
                <div></div>
                <img className='imageicon' src={QSGRW_Icon_Layers} alt='Layers Icon' />
                <p><strong>Layers</strong>: Allows access to parts of the 3D Model and the Point Cloud</p>
              </div>
              <br/>
              <div className='sideways'>
                <div></div>
                <img className='imageicon' src={QSGRW_Icon_Local_Layers} alt='Local Layers Icon' />
                <p><strong>Local Layers</strong>: Shows external data that has been integrated including ROAMES Analytics</p>
              </div>
              <br/>
              <div className='sideways'>
                <div></div>
                <img className='imageicon' src={QSGRW_Icon_Tool} alt='Tools Icon' />
                <p><strong>Tools</strong>: Features that allow you to interact spatially</p>
              </div>
              <br/>
              <div className='sideways'>
                <div></div>
                <img className='imageicon' src={QSGRW_Icon_Setting} alt='Settings Icon' />
                <p><strong>Settings</strong>: Ability to change the default setting</p>
              </div>
            </div>
          </div>
          <hr/>
          <h3>Accessing Imagery</h3>
          <div className='sideways'>
            <div className='vertical'>
              <img className='imageryexample' src={QSGRW_Imagery_Camera} alt='Camera Imagery' />
              <img className='imageryexample' src={QSGRW_Imagery_Street} alt='Street Imagery' />
            </div>
            <div>
              <p>Imagery can be accessed from two buttons in the bottom right of the main window. The two buttons represent two sources:</p>
              <ul>
                <li><strong>Base Imagery</strong>: Imagery from 3rd party</li>
                <li><strong>ROAMES Imagery</strong>: Imagery from ROAMES Camera or derived from the Lidar point cloud.</li>
              </ul>
            </div>
          </div>
          <br/>
          <div className='sideways'>
            <div className='vertical'>
              <img className='imageryexample' src={QSGRW_Imagery_Satellite} alt='Satellite Imagery' />
              <img className='imageryexample' src={QSGRW_Imagery_MaxHeight} alt='Max Height Imagery' />
            </div>
            <div>
              <p>Each set of imagery can be viewed by selecting &lt;left mouse click&gt; the relevant imagery from the choices provided. ROAMES imagery will however take precedence over Base imagery if selected. To see Base Imagery, ensure all ROAMES imagery is deselected.</p>
            </div>
          </div>
          <br/>
          <div className='sideways'>
            <div className='vertical'>
              <img className='imageryexample' src={QSGRW_Imagery_GreyScale} alt='Grey Scale Imagery' />
              <img className='imageryexample' src={QSGRW_Imagery_MaxHeight} alt='Max Height Imagery' />
            </div>
            <div>
              <p>There are two image types that have coloured the point cloud according to point cloud height. Colours that are at a height typical of electrical network are brighter e.g. red. For contrast Max Height uses blue for points near the ground, Greyscale uses a monochrome version of the camera imagery instead.</p>
            </div>
          </div>
          <hr/>
          <div className='sideways centered reversed'>
            <h3>Cycles</h3>
            <img className='imageicon' src={QSGRW_Icon_RW_Cycle} alt='Select Active Dataset Icon' />
          </div>
          <p>ROAMES uses cycles to differentiate information from captures. The latest 3D ROAMES Asset Model and Vegetation Models are the only ones shown.</p>
          <img className='imageexample' src={QSGRW_Cycle} alt='Cycle Selector Example' style={{width: 340}} />
          <p>Choosing the cycle will affect the following:</p>
          <br/>
          <ul>
            <li><strong>Point Cloud</strong>: Use Layer menu to switch on</li>
            <li><strong>Imagery</strong>: RGB and Point Cloud derived images</li>
          </ul>
          <hr/>
          <div className='sideways centered reversed'>
            <h3>Tools</h3>
            <img className='imageicon' src={QSGRW_Icon_Tool} alt='Tools Icon' />
          </div>
          <p>There are a variety of Tools available in the Tools Menu. The following are a few examples:</p>
          <img className='imageexample' src={QSGRW_Draw_Tool} alt='Local Layers Tool Example' style={{width: 237}} />
          <p>The Draw Tool allows you to make: Placemarks, Paths and Polygons. When saved they can be seen and managed in Local Layers</p>
          <br/>
          <div className='sideways'>
            <img src={QSGRW_Tools_Extract} alt='Extract LAZ Tool Example' style={{width: 275}} />
            <p>The extract LAZ tool (an optional extra) will allow you to extract the Point Cloud into a LAZ format so that you can review this externally to the ROAMES World application if required</p>
          </div>
          <br/>
          <div className='sideways'>
            <img src={QSGRW_Tool_Measure} alt='' style={{width: 275}} />
            <div>
              <p>The following Measure Tools are available:</p>
              <br/>
              <div className='vertical'>
                <div className='sideways centered'>
                  <img className='imageicon' src={QSGRW_Icon_DistanceTwoPoints} alt='Distance Between Two Points Icon' />
                  <p>Distance between two points</p>
                </div>
                <div className='sideways centered'>
                  <img className='imageicon' src={QSGRW_Icon_DistancePath} alt='Distance Along Path Icon' />
                  <p>Distance along a path</p>
                </div>
                <div className='sideways centered'>
                  <img className='imageicon' src={QSGRW_Icon_AreaPolygon} alt='Area Within Polygon Icon' />
                  <p>Area within a polygon</p>
                </div>
              </div>
            </div>
          </div>
          <hr/>
          <div className='sideways centered reversed'>
            <h3>Local Layers</h3>
            <img className='imageicon' src={QSGRW_Icon_Local_Layers} alt='ROAMES World Main Menu' />
          </div>
          <p>Local Layers provide access to information that is not found in the standard Layers Menu and is generated by the client. These include:</p>
          <ul>
            <li>Items Created in the Draw Tool e.g. Placemark</li>
            <li>ROAMES Analytics Assets</li>
            <li>Information that has been uploaded e.g. KML Layers (press this <img className='imageiconsmall' src={QSGRW_Icon_UploadKML} alt='Upload KML Icon' /> icon in the header to upload)</li>
          </ul>
          <br/>
          <p>Local Layers allows each item to do the following:</p>
          <br/>
          <div className='sideways'>
            <img src={QSGRW_Local_Layer02} alt='' style={{width: 187}} />
            <div className='vertical'>
              <div className='sideways'>
                <img className='imageiconsmall' src={QSGRW_Icon_Download} alt='Download Item Icon' />
                <p>Download item in KML or SHP format</p>
              </div>
              <div className='sideways'>
                <img className='imageiconsmall' src={QSGRW_Icon_Edit} alt='Configure Styling Icon' />
                <p>Configure the styling</p>
              </div>
              <div className='sideways'>
                <img className='imageiconsmall' src={QSGRW_Icon_Remove} alt='Remove Item Icon' />
                <p>Remove the Item</p>
              </div>
            </div>
          </div>
          <br/>
          <div className='sideways'>
            <img src={QSGRW_Local_Layer01} alt='' style={{width: 187}} />
            <p>Pressing the <img className='imageiconsmall' src={QSGRW_Icon_Create_Folder} alt='Create Folder Icon' /> icon in the local layers menu allows you to create folders. Other items in local layers can then be dragged and dropped into these folders for easier management</p>
          </div>
          <hr/>
          <h3>Screen Modes and Screenshots</h3>
          <p>ROAMES World allows you to control what is visible on the screen and create images showing only the spatial viewing panel:</p>
          <br/>
          <div className='sideways centered'>
            <img className='imageicon' src={QSGRW_Full_Screen} alt='Full Screen Icon' />
            <img className='imagepath' src={QSGRW_Path_Long} alt='' role='presentation' />
            <p>&lt;double left mouse click&gt; on this icon in the top right of ROAMES World will remove the browser frame and menus. &lt;left mouse click&gt; or &lt;Esc&gt; will bring them back.</p>
          </div>
          <br/>
          <div className='sideways centered'>
            <img className='imageicon' src={QSGRW_Icon_Fugro} alt='Minimize Sidebar Icon' />
            <img className='imagepath' src={QSGRW_Path_Long} alt='' role='presentation' />
            <p>&lt;left mouse click&gt; on the top left of ROAMES World will remove from view all ROAMES World application &lt;left mouse click&gt; of the icon will return them</p>
          </div>
          <br/>
          <div className='sideways centered'>
            <div className='vertical'>
              <img className='imageicon' src={QSGRW_Full_Screen} alt='Full Screen Icon' />
              <img className='imageicon' src={QSGRW_Icon_Fugro} alt='Minimize Sidebar Icon' />
            </div>
            <img className='imagepath' src={QSGRW_Path_Short} alt='' role='presentation' />
            <p>Use of these two buttons together will remove from screen all the browser and application apart from the Fugro button in the top left</p>
          </div>
          <br/>
          <div className='sideways centered'>
            <img className='imageicon' src={QSGRW_Screenshot} alt='Take Screenshot Icon' />
            <img className='imagepath' src={QSGRW_Path_Long} alt='' role='presentation' />
            <p>Using the ‘Take a Screenshot‘ button will create a full screen image that has all the browser and application removed. The image will be automatically downloaded and should appear on your browser.</p>
          </div>
          <hr/>
          <h3>Quick Layers</h3>
          <p>Quick layers allow rapid access to commonly used layers. They are prominently located to the left of the top tool bar. There are two types which are:</p>
          <ul>
            <li><strong>ROAMES pre-sets</strong>: Commonly used layers such as Network and Point Cloud which are configured by ROAMES</li>
            <li><strong>Customer Choices</strong>: One or more layers can be set up and used by a single user as required and linked to a single button</li>
          </ul>
          <p>The quick layer group can be switched on and off by &lt;left mouse click&gt; the appropriate button. A Customer Choice button can be removed using &lt;right mouse click&gt;</p>
          <br/>
          <p>The following will assist in managing layers:</p>
          <br/>
          <div className='vertical'>
            <div className='sideways centered'>
              <img className='imagelayer' src={QSGRW_Quick_Layer_Add} alt='Add Quick Layer Icon' />
              <p>After selecting layers using the Layers Menu use this to remember and reuse them. Allocate a name (shown if you hover your mouse over the button) and a button image</p>
            </div>
            <div className='sideways centered'>
              <img className='imagelayer' src={QSGRW_Quick_Layer_LayerOff} alt='Clear Quick Layers Icon' />
              <p>Clears all layers.</p>
            </div>
            <div className='sideways centered'>
              <img className='imagelayer' src={QSGRW_Quick_Layer_Undo} alt='Undo Quick Layers Icon' />
              <p>Reverts back to the previous layers</p>
            </div>
          </div>
          <hr/>
          <h3>Access to Street View</h3>
          <p>Both the Web and Desktop versions of ROAMES World use a keyboard shortcut to open Google Maps Street View at your cursor location. Pressing "Ctrl + Shift + S" will open a new tab in your default browser.</p>
          <br/>
          <p><span className='keyboardkey'>CTRL</span> + <span className='keyboardkey'>SHIFT</span> + <span className='keyboardkey'>S</span></p>
          <hr/>
          <h3>Point Cloud and Settings</h3>
          <p>The Point Cloud is available for each Cycle collected and can be switched on via the Layers Menu or the Quick Layers button at the top of the page. The Point Cloud will only be available when the height is low enough. There are many settings that can be changed which should be used to ensure optimum viewing. An example of this is Colour mode which, depending on customer requirements, can show the following</p>
          <br/>
          <div className='vertical'>
            <div className='sideways centered'>
              <img className='imagepointcloud' src={QSGRW_PC_Colour} alt='Colour Point Cloud Example' />
              <p><strong>Colour</strong>: Each Point is coloured based on the RGB imagery associated with the point.</p>
            </div>
            <div className='sideways centered'>
              <img className='imagepointcloud' src={QSGRW_PC_Height} alt='Height Point Cloud Example' />
              <p><strong>Height</strong>: Each point is coloured according to its height.</p>
            </div>
            <div className='sideways centered'>
              <img className='imagepointcloud' src={QSGRW_PC_Classified} alt='Classified Point Cloud Example' />
              <p><strong>Classified</strong>: Points can be classified according to client rules e.g. Proximity to conductor. This is not part of the standard product.</p>
            </div>
          </div>
          <hr/>
          <h3>Search</h3>
          <p>ROAMES World contains a search engine which is located at the top right of the application:</p>
          <p>Multiple search options are available including:</p>
          <br/>
          <ul>
            <li>Location (Address)</li>
            <li>ROAMES Asset ID’s</li>
            <li>Client Asset information (where available)</li>
          </ul>
          <br/>
          <p>For ID’s these need to be exact to find the Asset. Enter ID, or cut and paste, and press &lt;Enter&gt;</p>
          <br/>
          <img src={QSGRW_Search_Type} alt='Search Type Example' style={{width: 352}} />
          <br/>
          <p>The search engine is defaulted to a Location search. This is a graduated search for addresses. To start an address search start typing in the search box. Location can also found by entering Latitude and Longitude</p>
          <br/>
          <img src={QSGRW_Search} alt='Search Example' style={{width: 352}} />
          <br/>
          <p>The ROAMES Analytics web version also allows you to search through all tabular data and link across to a spatial view in ROAMES World (See ROAMES Analytics Quick Start guide)</p>
          <br/>
          <div className='sideways'>
            <img src={QSGA_Icon_RW_connected} alt='ROAMES World Connected Icon' style={{width: 40}} />
            <img src={QSGA_Icon_RW_Navi_Enable} alt='ROAMES World Navigation Enabled Icon' style={{width: 40}} />
          </div>
          <br/>
          <img src={QSGRW_RWConnected_PopUp} alt='ROAMES World Connected Pop Up' style={{width: 399}} />
        </div>
      </div>
    </Route>
  );
};
//
export { QuickStartGuideForRoamesWorld };
