import React, { useRef, useState } from "react";
import { Link } from 'react-router-dom';
import { news } from "../../../../data/News";
import { Routes } from "../routing/Routes";

const newsSlice = news.slice(0, 3);

const Home = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const onceRef = useRef(null);
  const loopRef = useRef(null);
  const videoRef = useRef(null);

  return <div className='app-content home'>
    <div className="herobanner">
      <div className="spiel">
        <h1 className="title">ROAMES VIRTUAL WORLD ASSET MANAGEMENT</h1>
        <div className="description">Optimise costs and reduce risk using ROAMES virtual world asset
        management to assess, monitor and maintain your powerline network.
        </div>
        <div className="buttons">
          <Link to={{ pathname: Routes.SUPPORT_GETSTARTED, state: { previousPath: Routes.ROOT, previousPathTitle: 'Home' } }} className="get-started">Get Started</Link>
          <p className='watchvideo' onClick={() => {
            if (videoRef.current?.play) {
              videoRef.current.scrollIntoView({behavior: "smooth"});
              videoRef.current.play();
            }
          }}><span className='icon icon-ondemand_video' /><span className='watchvideo__text'>Watch Video</span></p>
        </div>
      </div>
      <video ref={onceRef} id='bannervideo' className="bannervideo" controls={false} autoPlay={true} loop={false} muted={true} onEnded={() => {
        if (onceRef.current) {
          onceRef.current.style.display = 'none';
        }
        if (loopRef.current) {
          loopRef.current.style.display = '';
        }
      }}>
        <source type="video/mp4" src={process.env.REACT_APP_S3_BUCKET_URL + '/media/AppHub_HeroBanner_Intro.mp4'} />
      </video>
      <video ref={loopRef} id='bannervideo2' className="bannervideo" controls={false} autoPlay={true} loop={true} muted={true} style={{display: 'none'}}>
        <source type="video/mp4" src={process.env.REACT_APP_S3_BUCKET_URL + '/media/AppHub_HeroBanner_Infinity_Loop.mp4'} />
      </video>
    </div>
    <div className="news-items">
      {
        newsSlice.map((newsItem, index) => {
          // TODO: Add news-item--active to styles
          return <div key={'teaser-card-' + index}
            className={`news-item${activeIndex === index ? ' news-item--active' : ''}`}
            onMouseEnter={() => setActiveIndex(index)} onMouseLeave={() => setActiveIndex(-1)}>
            {activeIndex === index ? <div className='news-item__overlay'>
              <div className="news-teaser">{newsItem.teaser}</div>
              <Link className="news-see-more" to={`${Routes.NEWS}/${index}`}>See More</Link></div> : <div className="news-title">
              <div className="title-text">{newsItem.title}</div>
            </div>}
          </div>;
        })
      }
    </div>
    <video ref={videoRef} id='video' className="video" controls={true} autoPlay={false} loop={false} muted={false}>
      <source type="video/mp4" src={process.env.REACT_APP_S3_BUCKET_URL + '/media/FUGRO_ROAMES_SHORT_2019_v04.mp4'} />
    </video>
  </div>;
}

export {
  Home
};
