import React from "react";

// MSAL imports
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType, IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/NavigationClient";

import { Landing } from "./components/routing/Landing";
import { useHistory } from "react-router-dom";
import { loginRequest } from "./authConfig";
import { LinearProgress } from "@material-ui/core";
// import { ThemeChanger } from "./components/navbar/ThemeChanger";


// const themeVariants = [
//   {
//     icon: 'brightness_low',
//     variant: 'light'
//   },
//   {
//     icon: 'brightness_4',
//     variant: ''
//   },
//   {
//     icon: 'brightness_3',
//     variant: 'dark'
//   }
// ];

interface AppProps {
  pca: IPublicClientApplication
};

const App = ({ pca }: AppProps) => {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  // const [theme, setTheme] = React.useState('');
  // TODO: Colours properly, with rgba
  // const defaultThemeClass = 'insights-app';
  // const themeClass = theme.length > 0 ? `${defaultThemeClass} ${theme}` : defaultThemeClass;

  return <MsalProvider instance={pca}>
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest} 
      errorComponent={() => <p>Error... Please <a href="/">Refresh</a> and try again...</p>}
      loadingComponent={() => <LinearProgress />}>
        <div className={'insights-app'}>
          <Landing />
        </div>
    </MsalAuthenticationTemplate>
  </MsalProvider>
};

export default App;
