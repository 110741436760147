import React from "react";

import { Link as RRLink, useRouteMatch } from 'react-router-dom';

interface LinkProps {
  label: string;
  to: string;
  icon: string;
  expanded: boolean;
  exact: boolean;
};

const Link = ({label, to, icon, expanded, exact}: LinkProps) => {
  let match = useRouteMatch({
    path: to,
    exact
  });
  const className = `link${match ? ' link--active' : ''}`;
  return <RRLink to={to} className={className}>
    <div className={`link__icon icon-${icon}`}></div>
    {expanded ? <div className='link__label'>{label}</div> : <></>}
    {expanded && match ? <span className='icon-navigate_next link__chevron' /> : <></>}
  </RRLink>;
};

export {
  Link
};