import React, { useEffect, useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { callMsGraph } from '../../utils/MsGraphApiCall';
import { Main } from '../content/Main';
import { isResponseOk } from '../../utils/Util';


export const GRAPH_ENDPOINTS = {
  ME: "https://graph.microsoft.com/v1.0/me",
  MAIL: "https://graph.microsoft.com/v1.0/me/messages",
  PHOTO: "https://graph.microsoft.com/v1.0/me/photo/$value"
};
export interface GraphData {
  profile?: any;
  photo?: string;
  roles: string[];
}

const Landing = () => {
  const { inProgress } = useMsal();
  const [ graphData, setGraphData ] = useState<null|GraphData>(null);

  useEffect(() => {
      if (!graphData && inProgress === InteractionStatus.None) {
        const promises = [
          callMsGraph(GRAPH_ENDPOINTS.ME, false),
          callMsGraph(GRAPH_ENDPOINTS.PHOTO, false)
        ];
        // TODO: Roles need to be setup using Fugro AD. Removeed the AuthZ based roles due to having issues with /claims endpoint in apphub api
        Promise.allSettled(promises).then((responses) => {
          const profile = isResponseOk(responses[0]) ?
          {
            profile: responses[0].value
          } : {};
          const photo = isResponseOk(responses[1]) ? (() => {
            const url = window.URL || window.webkitURL; 
            const blobUrl = url.createObjectURL(responses[1].value);
            return {
              ...profile,
              photo: blobUrl
            };
          })() : profile;

          // TODO: Setup roles using Fugro AD

          const graphData = {
            ...photo,
            roles: []
          };
          setGraphData(graphData);
        });
      }
  }, [inProgress, graphData]);

  return (
      <>
          { 
            graphData ?
            <Main graphData={graphData} /> :
            <LinearProgress />
          }
      </>
  );
};

export {
  Landing
};