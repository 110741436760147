import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollToHash = () => {
  const location = useLocation();

  useEffect(() => {
    const hashValue = location.hash.split('#').length > 0 ? location.hash.split('#')[1] : null;

    if (hashValue) {
      const element = document.getElementById(hashValue);
      if (element) {
        element.scrollIntoView();
      }
    }
  });
}

export {
  useScrollToHash
}