import React from 'react';

const Navbar = () => {

  return (
    <nav className='navbar' role="navigation">
      <div className='navbar__details'>
        <span className='name'><span className='name__title'><b>ROAMES</b> APP HUB</span></span>
        <div className='spacer' />
        <div className='links'>
          <a href={process.env.REACT_APP_ROAMES_WORLD_URL} className="link icon-roames_world" target="_blank" rel="noreferrer" aria-label="ROAMES World"> </a>
          <a href={process.env.REACT_APP_ROAMES_ANALYTICS_URL} className="link icon-insert_chart" target="_blank" rel="noreferrer" aria-label="ROAMES Analytics"> </a>
        </div>
      </div>
    </nav>
  );
};

export { 
  Navbar
};