import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Application, applications } from "../../../../data/Applications";
import { replaceAtIndex } from "../../utils/Arr";
import { PopUp } from "../shared/Popup";

const Applications = () => {
  const [isModalOverviewOpen, setIsModalOverviewOpen] = useState([false]);
  return (
    <div className="app-content applications">
      <div className="applications">
        {applications.map((app: Application, index: number) => {
          const open = isModalOverviewOpen[index];
          const onClick = () => {
            const newOpenState = replaceAtIndex(
              isModalOverviewOpen,
              index,
              !open
            );
            setIsModalOverviewOpen(newOpenState);
          };
          return (
            <div key={index} className="applicationitem">
              <a
                className={`image ${app.image}`}
                href={app.link}
                target="_blank"
                rel="noreferrer"
                aria-label="Launch Application"
              >
                {" "}
              </a>
              <div className="details">
                <div className="icon-wrapper">
                  <a
                    className={`icon icon-${app.icon}`}
                    href={app.link}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Launch Application"
                  >
                    {" "}
                  </a>
                </div>
                <div className="namelinks">
                  <span className="name">{app.name}</span>
                  <div className="links">
                    <a
                      className={`link icon-launch`}
                      href={app.link}
                      target="_blank"
                      title="Launch Application"
                      rel="noreferrer"
                      aria-label="Launch Application"
                    >
                      {""}
                    </a>
                    <Link
                      className={`link icon-assignment`}
                      to={app.releaseNotes}
                      title="Release Notes"
                    />
                    <i
                      className={`link icon-info_outline`}
                      title="Product Overview"
                      onClick={onClick}
                    />
                    <Link
                      className={`link icon-flight_takeoff`}
                      to={app.getstarted}
                      title="Quick Start Guide"
                    />
                    {app.download ? (
                      <a
                        className={`link icon-file_download`}
                        href={app.download}
                        target="_blank"
                        title="Download"
                        rel="noreferrer"
                        aria-label="Download"
                      >
                        {""}
                      </a>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <span className="description">{app.description}</span>
              </div>
              <PopUp 
                contents={app.overview}
                display={open}
                closeAction={onClick}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { Applications };
