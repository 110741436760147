import React from "react";
import RWFeedback from '../../../../../img/rw_data_feedback.png';
import RWObjBro from '../../../../../img/rw_obj_browser.png';

const ContactUs = () => {
  return (
    <div className="sub-content contact-us">
      <div className="text-doc">
        <h1>Contact Us</h1>
        <p>Fugro has a dedicated support team able to assist with your enquiries.
          They have access to our operations teams and innovation centre.</p>
        <p>There are two main ways to contact Fugro ROAMES if you require support which are:</p>
        <ol>
          <li><strong>Submit a request</strong>: useful for general queries.</li>
          <li><strong>Provide Data Feedback</strong>: alerts the operations team to a Data issue.</li>
        </ol>
        <h2>Submit a Support Request</h2>
        <p>Submitting a support request via Zendesk allows you to register a support request for multiple categories
          such as feedback, training, and change requests.
          To access the form you will need to create an account with Zendesk for our support system.
        </p>
        <p>Please follow the <a className="link" href="https://roames.zendesk.com/hc/en-us/requests/new"
          target="_blank" rel="noreferrer">link to create a new support request.</a></p>
        <h2>Provide Data Feedback</h2>
        <p>Requests for a correction to the calculated 3D Model can be made through the ROAMES World application.
          If an issue is noted in the ROAMES Analytics model you can utilize the integration between the products
          to find the equivalent model item in ROAMES World (See ROAMES Analytics Quick Start Guide for details).</p>
        <p>To provide feedback from ROAMES World use the following instructions:</p>
        <ol>
          <li>While browsing virtual world in ROAMES World, click on one of the following model items to open object attributes:
            <ul>
              <li>Bay (found in schematics)</li>
              <li>Span</li>
              <li>Pole</li>
              <li>Pole Site</li>
              <li>Catenary (individual wire)</li>
            </ul>
          </li>
          <li>Click on the hyperlink <strong>Report a correction for...</strong> in the "Data Correction" row.<br/>
            <img src={RWObjBro} alt="ROAMES World Feedback Link Location"/>
          </li>
          <li>Fill in the attached form (see below) and press <strong>Submit</strong>.<br/>
            <img src={RWFeedback} alt={"ROAMES World Feedback Form"}/>
          </li>
        </ol>
      </div>
    </div>
  );
};

export {ContactUs};
