import React, { useState } from "react";
import { Navbar } from "../navbar/Navbar";
import { getRoutesByRoles } from "../routing/Roles";
import { Sidenav } from "../sidenav/Sidenav";
import { Link } from '../../components/routing/Link';
import { Content } from "../routing/Content";
import { GraphData } from "../routing/Landing";
import { BrowserRouter } from "react-router-dom";

interface MainProps {
  graphData: GraphData;
}

const Main = ({ graphData }: MainProps) => {
  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const links = getRoutesByRoles(graphData.roles);

  return <div className="main-content">
    <BrowserRouter>
      <Sidenav expanded={expanded} toggleExpanded={toggleExpanded} graphData={graphData}>
        {links.map((link: any, index: number) => <Link key={index} to={link.to} label={link.label} icon={link.icon} expanded={expanded} exact={link.exact}/>)}
      </Sidenav>
      <div className={`app-body${!expanded ? ' expanded' : ''}`}>
        <Navbar />
        <Content roles={graphData.roles} />
      </div>
    </BrowserRouter>
  </div>;
};

export {
  Main
}