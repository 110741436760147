import React from "react";
import { Route } from "react-router-dom";
import QSGA_Logo from '../../../../../img/quickstart/QSGA_Logo.png';
import QSGA_Navigation from '../../../../../img/quickstart/QSGA_Navigation.png';
import QSGA_Icon_Navigation_Menu from '../../../../../img/quickstart/QSGA_Icon_Navigation_Menu.png';
import QSGA_Sorting from '../../../../../img/quickstart/QSGA_Sorting.png';
import QSGA_Icon_RW_Disconnect from '../../../../../img/quickstart/QSGA_Icon_RW_Disconnect.png';
import QSGA_Icon_RW_connected from '../../../../../img/quickstart/QSGA_Icon_RW_connected.png';
import QSGA_Icon_RW_Navi_Disable from '../../../../../img/quickstart/QSGA_Icon_RW_Navi_Disable.png';
import QSGA_Icon_RW_Navi_Enable from '../../../../../img/quickstart/QSGA_Icon_RW_Navi_Enable.png';
import QSGA_Navi from '../../../../../img/quickstart/QSGA_Navi.png';
import QSGA_Cells_Funcs02 from '../../../../../img/quickstart/QSGA_Cells_Funcs02.png';
import QSGA_Cells_Funcs01 from '../../../../../img/quickstart/QSGA_Cells_Funcs01.png';
import QSGA_Header_Funcs from '../../../../../img/quickstart/QSGA_Header_Funcs.png';
import QSGA_Columns from '../../../../../img/quickstart/QSGA_Columns.png';
import QSGA_Favourites from '../../../../../img/quickstart/QSGA_Favourites.png';
import QSGA_Icon_Create from '../../../../../img/quickstart/QSGA_Icon_Create.png';
import QSGA_Icon_Remove from '../../../../../img/quickstart/QSGA_Icon_Remove.png';
import QSGA_Icon_Select from '../../../../../img/quickstart/QSGA_Icon_Select.png';

const QuickStartGuideForAnalytics = () => {
  return (
    <Route path="/support/get-started/analytics">
      <div className="sub-content quickstart">
        <div className='text-doc'>
          <img src={QSGA_Logo} alt='FUGRO ROAMES ANALYTICS' style={{width: 397}} />
          <h3>Quick Start Guide</h3>
          <p>The basics on how to use ROAMES Analytics (with new web data grids) effectively.</p>
          <br/>
          <h3>Getting Help</h3>
          <p>Help information can be obtained via the Support menu in the App Hub. It is strongly recommended that the Get Started information is read by any new user to the latest Fugro ROAMES products.</p>
          <hr/>
          <h3>Navigation</h3>
          <p>There are two ways of accessing the same data grids and the result may be very different for each:</p>
          <br/>
          <img src={QSGA_Navigation} alt='Navigation Buttons' style={{width: 412}} />
          <p>Use the navigation buttons, located at the top right of the screen, to continue your search after filtering i.e. to refine your search in detail grids.</p>
          <br/>
          <img src={QSGA_Icon_Navigation_Menu} alt='Navigation Menu' style={{width: 336}} />
          <p>Use the left-hand menu when you want to start a new unrelated search.</p>
          <hr/>
          <h3>Sorting</h3>
          <p>All columns in the data grid can be sorted.</p>
          <br/>
          <img src={QSGA_Sorting} alt='Sorting Menu' style={{width: 535}} />
          <br/>
          <p>To sort on a column &lt;left mouse click&gt; on the column name.</p>
          <br/>
          <div className='sideways'>
            <div></div>
            <ul>
              <li>Click 1 – sort ascending (up arrow)</li>
              <li>Click 2 – sort descending (down arrow)</li>
              <li>Click 3 – cancel sort</li>
            </ul>
          </div>
          <br/>
          <p>To sort on multiple columns use the &lt;shift key&gt; and instructions above for each column</p>
          <hr/>
          <h3>ROAMES World Integration</h3>
          <p>The spatial location of all records can be found in ROAMES World when it is open in another window.</p>
          <br />
          <div className='vertical'>
            <div className='sideways'>
              <img src={QSGA_Icon_RW_Disconnect} alt='ROAMES World Disconnected Icon' style={{width: 40}} />
              <p>If button is clear, ROAMES World is unavailable i.e. not connected. &lt;left mouse click&gt; on this button will launch ROAMES World</p>
            </div>
            <div className='sideways'>
              <img src={QSGA_Icon_RW_connected} alt='ROAMES World Connected Icon' style={{width: 40}} />
              <p>If button is blue, ROAMES World is available i.e. connected.</p>
            </div>
            <div className='sideways'>
              <img src={QSGA_Icon_RW_Navi_Disable} alt='ROAMES World Navigation Disabled Icon' style={{width: 40}} />
              <p>ROAMES World Navigation is disabled</p>
            </div>
            <div className='sideways'>
              <img src={QSGA_Icon_RW_Navi_Enable} alt='ROAMES World Navigation Enabled Icon' style={{width: 40}} />
              <p>ROAMES World Navigation is enabled</p>
            </div>
          </div>
          <br/>
          <img src={QSGA_Navi} alt='ROAMES World Navigation Example' style={{width: 535}} />
          <br/>
          <p>To navigate to equivalent data in ROAMES World:</p>
          <br/>
          <div className='sideways'>
            <div></div>
            <ul>
              <li>Use left and right buttons on white background to navigate all records</li>
              <li>Use left and right buttons on blue background to navigate selected records</li>
              <li>Click on individual record to see in ROAMES World</li>
            </ul>
          </div>
          <hr/>
          <h3>Cell Functions</h3>
          <p>Use &lt;right mouse click&gt; on any cell in the data grid to reveal a menu of functions:</p>
          <br/>
          <img src={QSGA_Cells_Funcs02} alt='Context Menu Cell Functions' style={{width: 155}} />
          <br/>
          <p>This menu is context-sensitive as follows:</p>
          <br/>
          <div className='sideways'>
            <div></div>
            <ul>
              <li><strong>Row Mode</strong>: If any record is selected using check box on left side (selected record turns blue) then applies to rows only</li>
              <li><strong>Selection Mode</strong>: By selecting multiple cells (cells go blue) applies to all the cells selected</li>
              <li><strong>Cell Mode</strong>: Applies to the single cell selected (only works if no rows selected)</li>
            </ul>
          </div>
          <hr/>
          <h3>Column Header Functions</h3>
          <img src={QSGA_Header_Funcs} alt='Column Headers' style={{width: 535}} />
          <br/>
          <p>Click on the Column menu icon to reveal the Column Functions. It is found in the top right corner of each column header when you hover over it with your cursor.</p>
          <br/>
          <div className='sideways'>
            <img src={QSGA_Cells_Funcs01} alt='Column Headers Cell Functions' style={{width: 215}} />
            <div className='vertical'>
              <p>It contains useful functions including:</p>
              <br/>
              <ul>
                <li>Ability to pin a column to the left or right of the grid</li>
                <li>Ability to filter on the column. One of these filter types will have been assigned: Date, Number, Text, Pick list</li>
              </ul>
            </div>
          </div>
          <br/>
          <h3>Hidden columns</h3>
          <p>Hide / Unhide columns to make the grid easier to manage</p>
          <br/>
          <div className='sideways'>
            <img src={QSGA_Columns} alt='Column Selector' style={{width: 215}} />
            <div className='vertical'>
              <p>Press the Columns button on the right of the grid to see all columns in the Grid including those hidden.</p>
              <br/>
              <p>Use the checkboxes to hide or reveal columns or groups of columns</p>
            </div>
          </div>
          <hr/>
          <h3>Favourites</h3>
          <img src={QSGA_Favourites} alt='Favourite Selector' style={{width: 215}} />
          <p>The Data Grids can remember some of your favourite settings such as:</p>
          <br/>
          <ul>
            <li>Sort order</li>
            <li>Filter criteria</li>
            <li>If the column is hidden or not</li>
            <li>Column Position (drag and drop in the group)</li>
          </ul>
          <br/>
          <p>Favourites controls are on the top right of the screen.</p>
          <br/>
          <div className='vertical'>
            <div className='sideways'>
              <img src={QSGA_Icon_Create} alt='Create Favourite Icon' style={{width: 20}} />
              <p>To create a favourite set up the grid as you like it and then use this button.</p>
            </div>
            <div className='sideways'>
              <img src={QSGA_Icon_Remove} alt='Remove Favourite Icon' style={{width: 20}} />
              <p>To remove a favourite and see the default criteria use this button.</p>
            </div>
            <div className='sideways'>
              <img src={QSGA_Icon_Select} alt='Select Favourite Icon' style={{width: 20}} />
              <p>To select a favourite use this button.</p>
            </div>
          </div>
        </div>
      </div>
    </Route>
  );
};

export { QuickStartGuideForAnalytics };
