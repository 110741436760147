import React from "react";
import { User } from './User';
import { Links } from './Links';
import { Signout } from "./Signout";
import { Logo } from './Logo';
import { GraphData } from "../routing/Landing";

interface SidenavProps {
  expanded: boolean;
  toggleExpanded: () => void;
  children: any[];
  graphData: GraphData;
};

const Sidenav = ({ expanded, toggleExpanded, children, graphData }: SidenavProps) => {
  const defaultSidenavClass = 'sidenav';
  const sidenavClass = expanded ? `${defaultSidenavClass} expanded` : defaultSidenavClass;
  return <nav className={sidenavClass}>
    <Logo expanded={expanded} toggleExpanded={toggleExpanded} />
    <>
      <User expanded={expanded} graphData={graphData} />
      <Links>
        {children}
      </Links>
      <Signout expanded={expanded} />
    </>
  </nav>
}

export {
  Sidenav
};