import React from "react";
import { ChangelogMarkdown } from "../../../../../components/changelog/ChangelogMarkdown";
import { useScrollToHash } from "../../../utils/useScrollToHash";
import { ReleaseNotesSwitcher } from "./ReleaseNotesSwitcher";

const ReleaseNotesAppHub = () => {
  useScrollToHash();

  return <div className="release-notes">
        <ReleaseNotesSwitcher value='apphub' />
        <ChangelogMarkdown/>
    </div>;
};

export {
  ReleaseNotesAppHub
};