import { News } from "../content/News";
import { Home } from "../content/Home";
import { Support } from "../content/Support";
import { Applications } from "../content/Applications";
import { Routes } from "./Routes";

// TODO: Incorporate components from other applications here
const roleMap = {
  apphubuser: [
    {
      to: Routes.EXAMPLE,
      label: 'Example',
      icon: 'home',
      component: Home,
      exact: true
    }
  ]
};

const defaultRoutes = [
  {
    to: Routes.ROOT,
    label: 'Home',
    icon: 'home',
    component: Home,
    exact: true
  },
  {
    to: Routes.NEWS,
    label: 'News',
    icon: 'stars',
    component: News,
    exact: false
  }, 
  {
    to: Routes.APPS,
    label: 'Applications',
    icon: 'apps',
    component: Applications,
    exact: true
  },
  {
    to: Routes.SUPPORT,
    label: 'Support',
    icon: 'contact_support',
    component: Support,
    exact: false
  }
];

const acceptedRoles = Object.keys(roleMap);

const filterRoles = (roles: string[]) => {
  return roles.filter((role: string) => {
    return acceptedRoles.includes(role);
  });
};

const getRoutesByRoles = (roles: string[]) => {
  const roleRoutes = roles.flatMap((role: any) => roleMap[role]);
  return defaultRoutes.concat(roleRoutes);
};

const pathMatch = (path: string, to: string, exact: boolean) => {
  return exact ? to === path : path.startsWith(to);
};

const findRouteByRoles = (roles: string[], path: string) => {
  const isDefaultRoute = defaultRoutes.find((defaultRoute) => {
    return pathMatch(path, defaultRoute.to, defaultRoute.exact);
  });
  const foundRoute = isDefaultRoute ? isDefaultRoute : roles.find((role) => {
    // a route with the same path to it as the one we're looking at...
    return roleMap[role].find((roleRoute) => {
        return pathMatch(path, roleRoute.to, roleRoute.exact);
    });
  });
  return foundRoute;
};

export {
  filterRoles,
  getRoutesByRoles,
  findRouteByRoles
}