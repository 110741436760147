import React from "react";
import { ReleaseNotesSwitcher } from "./ReleaseNotesSwitcher";

const ReleaseNotes = () => {
  return (
    <div className="release-notes">
      <ReleaseNotesSwitcher value='' />
      <div className='markdown-body' style={{
        boxSizing: 'border-box',
        minWidth: 200,
        maxWidth: 980,
        margin: '0 auto',
        padding: 15
      }}>
        <h1>Overview</h1>
        <blockquote>Note: Please select a product from the dropdown on the top right and view the product's release notes.</blockquote>
        <p>Categories are explained as follows:</p>
        <h3>Fixes</h3>
        <p>For any bug fixes.</p>
        <h3>Features</h3>
        <p>For any brand new functionality.</p>
        <h3>Changes</h3>
        <p>For changes in existing functionality.</p>
        <h3>Deprecated</h3>
        <p>For soon-to-be-removed features.</p>
        <h3>Removed</h3>
        <p>For now removed features.</p>
        <h3>Security</h3>
        <p>In case of vulnerabilities.</p>
      </div>
    </div>
  );
};

export {
  ReleaseNotes
};