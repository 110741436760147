const ROOT = '/';

const APPS = '/apps';
const APPS_OVERVIEW = `${APPS}/overview`;
const APPS_OVERVIEW_ANALYTICS = `${APPS_OVERVIEW}/analytics`;
const APPS_OVERVIEW_WORLD = `${APPS_OVERVIEW}/world`;

const NEWS = '/news';

const SUPPORT = '/support';
const SUPPORT_CONTACTUS = `${SUPPORT}/contactus`;
const SUPPORT_GETSTARTED = `${SUPPORT}/get-started`;
const SUPPORT_GETSTARTED_ANALYTICS = `${SUPPORT_GETSTARTED}/analytics`;
const SUPPORT_GETSTARTED_WORLD = `${SUPPORT_GETSTARTED}/world`;
const SUPPORT_GLOSSARY = `${SUPPORT}/glossary`;
const SUPPORT_GLOSSARY_WITH_ID = `${SUPPORT_GLOSSARY}/:glossaryHandle`;
const SUPPORT_RELEASENOTES = `${SUPPORT}/release-notes`;
const SUPPORT_RELEASENOTES_APPHUB = `${SUPPORT}/release-notes/apphub`;
const SUPPORT_RELEASENOTES_ANALYTICS = `${SUPPORT}/release-notes/analytics`;
const SUPPORT_RELEASENOTES_WORLD = `${SUPPORT}/release-notes/world`;

const EXAMPLE = '/example';

const Routes = {
  ROOT,

  APPS,
  APPS_OVERVIEW,
  APPS_OVERVIEW_ANALYTICS,
  APPS_OVERVIEW_WORLD,

  NEWS,

  SUPPORT,
  SUPPORT_CONTACTUS,
  SUPPORT_GETSTARTED,
  SUPPORT_GETSTARTED_ANALYTICS,
  SUPPORT_GETSTARTED_WORLD,
  SUPPORT_GLOSSARY,
  SUPPORT_GLOSSARY_WITH_ID,
  SUPPORT_RELEASENOTES,
  SUPPORT_RELEASENOTES_APPHUB,
  SUPPORT_RELEASENOTES_ANALYTICS,
  SUPPORT_RELEASENOTES_WORLD,

  EXAMPLE
};

export {
  Routes
}