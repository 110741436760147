import React from "react";
import { ChangelogMarkdown } from "../../../../../components/roamesworldchangelog/ChangelogMarkdown";
import { useScrollToHash } from "../../../utils/useScrollToHash";
import { ReleaseNotesSwitcher } from "./ReleaseNotesSwitcher";


const ReleaseNotesRoamesWorld = () => {
  useScrollToHash();

  return <div className="release-notes">
        <ReleaseNotesSwitcher value='world' />
        <ChangelogMarkdown/>
    </div>;
};

export {
  ReleaseNotesRoamesWorld
};