import React from 'react';

class MicroUIChangelog extends React.Component<{host: string, history: any}> {
  componentDidMount() {
    const { host } = this.props;

    const scriptId = `micro-frontend-script-Changelog`;

    if (document.getElementById(scriptId)) {
      this.renderMicroFrontend();
      return;
    }

    const script = document.createElement('script');
    script.id = scriptId;
    script.crossOrigin = '';
    script.src = `${host}/renderchangelog.js`;
    script.onload = this.renderMicroFrontend;
    document.head.appendChild(script);
  }

  componentWillUnmount() {
    if (window[`unmountChangelog`]) {
      window[`unmountChangelog`](`Changelog-container`);
    }
  }

  renderMicroFrontend = () => {
    const { history } = this.props;

    if (window[`renderChangelog`]) {
      window[`renderChangelog`](`Changelog-container`, history);
    }
  };

  render() {
    return <main id={`Changelog-container`} />;
  }
}

export {
  MicroUIChangelog
};