import React from 'react'
import './style.css';

export const ChangelogMarkdown = () => {
    return (
        <div className='markdown-body' style={{
            boxSizing: 'border-box',
            minWidth: 200,
            maxWidth: 980,
            margin: '0 auto',
            padding: 15
          }}>
            <h1>ROAMES World Release Notes</h1>
<p>All notable changes to this project will be documented in this file.</p>
<h2>
<a className="anchor" href="#2020.1.01.1" id="2020.1.01.1">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>[2020.1.01.1] - 2020-07-10</h2>
<h3>
<a className="anchor" href="#2020.1.01.1-added" id="2020.1.01.1-added">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Added</h3>
<ul>
<li>Added Toggle to support extracting as compressed laz or uncompressed las.</li>
<li>Added support for selecting desired CRS when extracting las data.</li>
<li>Added support for increased area width when extracting las data.</li>
</ul>
<h3>
<a className="anchor" href="#2020.1.01.1-fixed" id="2020.1.01.1-fixed">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Fixed</h3>
<ul>
<li>Incorrect login flow for Object Browser on Desktop. It no longer opens an external window when clicking login button.</li>
<li>Offline mode showing Invalid Client by default.</li>
<li>ColourByHeight toggle being shown when not supported when extracting las data.</li>
<li>Incorrect handling of Multi-Cycle layers for types other than point cloud.</li>
<li>Quick Layers.</li>
<li>Customer Terrain Shader.</li>
</ul>
<h2>
<a className="anchor" href="#2019.1.02.003" id="2019.1.02.003">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>[2019.1.02.003] - 2019-09-03</h2>
<h3>
<a className="anchor" href="#2019.1.02.003-added" id="2019.1.02.003-added">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Added</h3>
<ul>
<li>Added support for individual colours for pole assets.</li>
<li>Added a visible placemark based on the last search coordinates when searching for locations.</li>
<li>Added support for gesture-based navigation on touch-capable devices.</li>
</ul>
<h3>
<a className="anchor" href="#2019.1.02.003-changed" id="2019.1.02.003-changed">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Changed</h3>
<ul>
<li>Temporarily removed automatic login for asset popups to resolve logout issue.</li>
</ul>
<h3>
<a className="anchor" href="#2019.1.02.003-fixed" id="2019.1.02.003-fixed">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Fixed</h3>
<ul>
<li>A scenario where the application would not navigate to any KML placemark files that were locally added.</li>
<li>Labels could still be visible after their associated icons were hidden.</li>
<li>Poor performance in terrain data decoding.</li>
<li>Poor memory usage of point cloud data.</li>
</ul>
<h2>
<a className="anchor" href="#2019.1.02.002" id="2019.1.02.002">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>[2019.1.02.002] - 2019-08-07</h2>
<h3>
<a className="anchor" href="#2019.1.02.002-added" id="2019.1.02.002-added">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Added</h3>
<ul>
<li>Added improved consistency of selection area for clamped to ground lines.</li>
<li>Added the ability to loop presentations.</li>
<li>Added the option for a transition delay between individual stages in a presentation.</li>
<li>Added the option to combine transition delays between individual stages with ‘timed’ mode to automatically play back a presentation.</li>
</ul>
<h3>
<a className="anchor" href="#2019.1.02.002-changed" id="2019.1.02.002-changed">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Changed</h3>
<ul>
<li>Random geometry colours now persist after selection.</li>
</ul>
<h3>
<a className="anchor" href="#2019.1.02.002-fixed" id="2019.1.02.002-fixed">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Fixed</h3>
<ul>
<li>A memory leak for clamped to ground polygons referencing terrain data that is no longer visible.</li>
<li>Clamped to ground polygons leaving partially rendered areas on the terrain when turned off.</li>
<li>Point cloud outline being invisible in orthographic view.</li>
<li>Poor memory usage of terrain data.</li>
<li>An issue when toggling local layers on and off.</li>
</ul>
<h2>
<a className="anchor" href="#2019.1.02.001" id="2019.1.02.001">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>[2019.1.02.001] - 2019-06-03</h2>
<h3>
<a className="anchor" href="#2019.1.02.001-added" id="2019.1.02.001-added">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Added</h3>
<ul>
<li>Added improved behaviour of the tenant dropdown list when typing the tenant name.</li>
<li>Added automatic login upon opening Asset popups.</li>
<li>Made it easier to close a popup bubble when clicking on certain data layer objects, either by hitting escape or clicking outside of the opened popup.</li>
<li>Added additional point cloud quality settings which greatly increase the rendering distance.</li>
<li>Improved selection of clamped placemark objects.</li>
<li>Improved textured PLY mesh loading times.</li>
<li>Improved rendering of presentation slides to ensure everything renders correctly behind the blurred slides.</li>
<li>Improved interaction with gizmos in presentation mode.</li>
<li>Improved shadow rendering quality when shadows are enabled.</li>
<li>Added post processing effects accessible from the settings menu.</li>
<li>Added an option to render terrain contour lines with altitude labels, accessible from the Terrain section in the settings panel.</li>
<li>Added ability to drag folders and layers around in the local layers panel.</li>
</ul>
<h3>
<a className="anchor" href="#2019.1.02.001-changed" id="2019.1.02.001-changed">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Changed</h3>
<ul>
<li>Updated the watermark to the new Fugro Roames logo.</li>
</ul>
<h3>
<a className="anchor" href="#2019.1.02.001-fixed" id="2019.1.02.001-fixed">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Fixed</h3>
<ul>
<li>Switching regions during the tenant selection screen potentially causing connectivity issues.</li>
<li>Not being able to search for assets that contain spaces.</li>
<li>Location search displaying incorrect results.</li>
<li>Mini nifty disc not reappearing when search is used.</li>
<li>Lines rendering on top of point cloud outlines even if they’re farther away.</li>
<li>Not all point cloud settings being changed back to default when the reset button is pressed.</li>
<li>A crash when loading or creating geometry that is extruded.</li>
<li>Data layer cycle switching not showing new cycle data if there were no items disabled from the previous cycle.</li>
<li>A rare issue where lines would not behave correctly.</li>
<li>A bug where importing local KML files would not parse the ‘tesellated’ flag on geometry objects.</li>
<li>Polygon outlines not rendering around polygons correctly in some cases.</li>
<li>A bug where data consisting of clamped polygons was not always being cleared correctly from the terrain.</li>
<li>Being unable to delete an image from presentation mode in some situations.</li>
<li>Minimal UI not rendering correctly when in ‘Fly’ mode after presentation mode was used.</li>
<li>Various issues with presentation mode text rendering and state transitions.</li>
<li>Presentation slides not rendering at very high altitudes.</li>
<li>Inability to save presentation changes if the only thing changed is the slide distance.</li>
<li>Editing gizmos not rendering in presentation mode when moving slides around.</li>
<li>Display issues that would occur when the browser window is resized during multi-view.</li>
<li>Mouse cursor not changing back from measuring icon when exiting measure mode.</li>
<li>Not being able to jump in walk mode.</li>
<li>A crash that could occur if ExtractLas extracts invalid data.</li>
</ul>
<h2>
<a className="anchor" href="#2018.2.06.007" id="2018.2.06.007">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>[2018.2.06.007] - 2019-03-26</h2>
<h3>
<a className="anchor" href="#2018.2.06.007-added" id="2018.2.06.007-added">
<svg aria-hidden="true" className="octicon octicon-link" height="16" version="1.1" viewbox="0 0 16 16" width="16">
  <path d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path>
</svg>
</a>Added</h3>
<ul>
<li>Added initial release.</li>
</ul>

        </div>
    )
}
