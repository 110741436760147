import React from "react";
import { getRoutesByRoles } from "../routing/Roles";
import { Switch, Route } from "react-router-dom";
import { NotFound } from "../content/NotFound";
import { ProtectedRoute } from "../routing/ProtectedRoute";

interface ContentProps {
  roles: string[];
}

const Content = ({roles}: ContentProps) => {
  const components = getRoutesByRoles(roles);
  return (
    <>
      <Switch>
        {components.map((component, index) => {
          const Component = component.component;
          return (
            <ProtectedRoute key={index} path={component.to} exact={component.exact} roles={roles}>
              <Component roles={roles} />
            </ProtectedRoute>
          );
        })}
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
};

export {
  Content
}