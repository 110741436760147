import { useMsal } from "@azure/msal-react";
import React from "react";

interface SignoutProps {
  expanded: boolean;
};

const Signout = ({expanded}: SignoutProps) => {
  const { instance } = useMsal();
  const logout = () => {
    instance.logoutRedirect();
  };

  return <div className='signout' onClick={logout}>
    {
      expanded ? <><div className="signout__icon icon-logout"></div><span className='signout__text'>Log Out</span></> :
      <div className="signout__icon icon-logout"></div>
    }
  </div>;
};

export {
  Signout
};