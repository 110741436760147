const news = [
  {
    application: "Analytics",
    type: "Announcement",
    date: new Date('August 31, 2021'),
    title: 'New ROAMES Analytics',
    teaser: 'Introducing the new ROAMES Analytics: Next generation web-enabled analytics replace legacy Excel-based downloads',
    moreinfo: 'ROAMES Analytics has been completely reconstructed to deliver the performance, scalability, and accessibility ' +
     'required by our clients. Instead of a connection through Excel, ROAMES Analytics lives in a web browser – which you ' +
     'can easily find via the new ROAMES App Hub, a one-stop web portal to all ROAMES content.\n\n' +
     'At least 100x faster than its predecessor, the enhanced ROAMES Analytics can load thousands of ' +
     'records instantaneously and tens of millions in under 5 seconds. It can also save user-configured ' +
     'filters or queries, and can export to Excel, Esri, or other corporate systems. ROAMES Analytics features ' + 
     'a direct connection to ROAMES World, allowing you to explore the map visualisation as you quickly toggle ' + 
     'through records. This is a powerful capability that enables remote, desktop-based risk assessment workflows. Beta testing has now successfully completed.',
    link: '/support/release-notes/analytics/#1.0.5'
  },
  {
    application: "ROAMES World",
    type: "Announcement",
    date: new Date('August 31, 2021'),
    title: 'New ROAMES World',
    teaser: 'ROAMES World gets a major update',
    moreinfo: 'With a simplified, user-friendly layout and refreshed styling, ROAMES World is more powerful than ever. Access ' +
    'ROAMES World for web or for desktop from the new ROAMES App Hub, a one-stop portal to all ROAMES content. ' +
    'ROAMES World on the web has successfully completed its Beta testing and is now available at the new address of world.roames.com, ' +
    'or you can access from the application link in the app hub.',
    link: '/support/release-notes/world#2020.1.01.1'
  },
  {
    application: "App Hub",
    type: "Announcement",
    date: new Date('August 31, 2021'),
    title: 'Introducing App Hub',
    teaser: 'New ROAMES App Hub replaces legacy ROAMES Portal as the home for all ROAMES applications, content, and information.',
    moreinfo: 'ROAMES App Hub is a one-stop web portal to access applications like ROAMES World and ROAMES Analytics, find support ' +
    'documentation and help guides, get news updates or product release notes, and more.\n\n' + 
    'ROAMES App Hub replaces the legacy Portal, which will run in parallel for the remainder of 2021 as we continue to migrate all features into the new App Hub.',
    link: '/support/release-notes/apphub/#1.0.1'
  }
]

export {
  news
}