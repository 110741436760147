import React from 'react';
import { glossary } from '../../../../../data/Glossary';
import marked from 'marked';
import { Link } from 'react-router-dom';
import { useScrollToHash } from '../../../utils/useScrollToHash';

export interface GlossaryEntry {
  title: string;
  handle: string;
  description: string;
  images?: string[];
  related: string[];
  aka: string[];
};

interface GlossaryTOCProps {
  glossary: GlossaryEntry[];
}

const GlossaryTOC = ({glossary}: GlossaryTOCProps) => {
  return <div className="toc">
    <h2>Contents</h2>
    {
      glossary.map((item: GlossaryEntry, index) => {
        return <Link key={index} to={`#${item.handle}`}>{item.title}</Link>;
      })
    }
  </div>;
};

const getDescriptionHtml = (description: string) => {
  const tokens = marked.lexer(description);
  const html = marked.parser(tokens);
  return html;
};


interface GlossaryItemProps {
  glossary: GlossaryEntry[];
  item: GlossaryEntry;
};

const GlossaryItem = ({glossary, item}: GlossaryItemProps) => {
  return <div id={item.handle} className="tile">
    <div className="summary">
      <div className="details">
        <div className="title">{item.title}</div>
      </div>
    </div>
    <div className="glossarydetails">
      <div className="description" dangerouslySetInnerHTML={{__html: getDescriptionHtml(item.description) }} />
      { item.images !== undefined ? <div className='images'> {
        item.images.map((image, index) => {
          return <img key={index} className="image" src={image} alt={item.title} />
        }) 
      }</div>: <></> }
      {
        item.related.length > 0 ?
        <>
          <b>Related Information:</b>
          <ul>
          { 
            item.related.map((handle, index) => {
              const relatedIndex = glossary.findIndex((value) => {
                return handle === value.handle;
              });
              const related = glossary[relatedIndex];
              return <li key={index}><Link to={`#${related.handle}`}>{related.title}</Link></li>;
            })
          }
          </ul>
        </> : <></>
      }
      { item.aka.length > 0 ? <>
        <b>Also Known As:</b>
        <ul>
          { item.aka.map((aka, index) => {return <li key={index}>{ aka }</li>;}) }
        </ul>
      </> : <></>}
    </div>
  </div>;
};

interface GlossaryComponentProps {
  glossary: GlossaryEntry[];
}

const GlossaryComponent = ({glossary}: GlossaryComponentProps) => {
  useScrollToHash();

  return <div className="inner">
    <h1>Glossary</h1>
    <GlossaryTOC glossary={glossary} />
    {
      glossary.map((item: GlossaryEntry, index: number) => {
        return <GlossaryItem key={index} glossary={glossary} item={item} />;
      })
    }
  </div>;
};

const Glossary = () => {
  //TODO: Make async for when this is loaded from elsewhere
  return <div id="page-top" className="sub-content glossary">
    <GlossaryComponent glossary={glossary} />
  </div>
};

export {
  Glossary,
  GlossaryComponent
}