import React, { useState } from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { news } from "../../../../data/News";
import { replaceAtIndex } from "../../utils/Arr";

interface Card {
  application: string;
  type: string;
  date: Date;
  title: string;
  teaser: string;
  moreinfo: string;
  link?: string;
}

const News = () => {
  const match = useRouteMatch('/news/:open') as any;
  const open = match?.params?.open;
  const defaultOpenState = open !== undefined ? news.map((_, index) => open === index.toString()) : news.map(() => false);
  const [openState, setOpenState] = useState(defaultOpenState);

  const getIconForApplication = (application: string) => {
    switch (application) {
      case "Analytics": {
        return "icon-Icon_Analytics";
      }
      case "App Hub": {
        return "icon-Icon_AppHub";
      }
      case "ROAMES World": {
        return "icon-Icon_RW";
      }
      default: {
        return "icon-stars";
      }
    }
  };

  return (
    <div className={"app-content"}>
      <div className="app-content news">
        {news.map((card: Card, index: number) => {
          const open = openState[index];
          const onNewsClick = () => {
            const newOpenState = replaceAtIndex(openState, index, !open);
            setOpenState(newOpenState);
          };

          const iconClassName = `icon ${getIconForApplication(card.application)} ${open ? "open" : "closed"}`;

          return (
            <div key={index} className="tile" onClick={onNewsClick}>
              <div className="summary">
                <div className={iconClassName} />
                <div className="date">
                  <div>{card.type}</div>
                  <div>{card?.date.toDateString()}</div>
                </div>
                <div className="details">
                  <div className="title">{card.title}</div>
                  <div className="teaser">{card.teaser}</div>
                </div>
                <div className="extendButton">
                  {open ? 'Read Less' : 'Read More'}
                  {open ? (
                    <i className="icon tile-open icon-navigate_next" />
                  ) : (
                    <i className="icon tile-closed icon-navigate_next" />
                  )}
                </div>
              </div>
              <div className={"description " + (open ? "open" : "closed")}>{card.moreinfo}{card.link ? <span> <Link to={card.link}>See More</Link></span> : <></>}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { News };
