import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import React from 'react';
import DefaultUserPicture from '../../../../img/default_user_picture.png'
import { GraphData } from '../routing/Landing';

interface ProfileDataProps {
  graphData: GraphData;
};

const retrieveName = (profile: any | undefined, accounts: Partial<Pick<AccountInfo, "homeAccountId" | "localAccountId" | "username">>[]) => {
  if (profile?.givenName && profile?.surname) {
    return `${profile.givenName} ${profile.surname}`
  } else if (profile?.givenName) {
    return profile.givenName;
  } else if (profile?.surname) {
    return profile.surname;
  } else if (profile?.username) {
    return profile.username
  } else if (profile?.displayName) {
    return profile.displayName
  } else {
    return accounts[0]?.username;
  }
}

const ProfileData = ({ graphData }: ProfileDataProps) => {
  const { accounts } = useMsal();
  
  return <div className='user__details'>
    <div className='user__name'>{retrieveName(graphData.profile, accounts)}</div>
    { graphData.profile?.jobTitle ? <div className='user__title'>{graphData.profile.jobTitle}</div> : <></> }
  </div>;
};

interface UserProps {
  expanded: boolean;
  graphData: GraphData;
};

const User = ({expanded, graphData}: UserProps) => {
  const styles = {
    backgroundImage: graphData.photo ? `url(${graphData.photo})` : `url(${DefaultUserPicture})`
  };

  return <div className='user'>
    <div className='user__photo' style={styles}/>
    {
      expanded ? <ProfileData graphData={graphData} /> : <></>
    }
  </div>;

};

export {
  User
};
