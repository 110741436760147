import React from "react";
import { useHistory } from "react-router-dom";
import { MicroUIChangelog } from "../../MicroUIChangelog";
import { ReleaseNotesSwitcher } from "./ReleaseNotesSwitcher";


const ReleaseNotesAnalytics = () => { 
  const history = useHistory();

  // NB. To test this component locally, run the analytics app on port 3001 and change the host below to
  // http://localhost:3001
  return (
    <div className="release-notes">
        <ReleaseNotesSwitcher value='analytics' />
        <MicroUIChangelog host={process.env.REACT_APP_ROAMES_ANALYTICS_URL} history={history} />
    </div>
  );
};

export {
  ReleaseNotesAnalytics
};