const baymodel = `${process.env.PUBLIC_URL}/img/BayModel.png`;
const clearancebands = `${process.env.PUBLIC_URL}/img/ClearanceBands.png`;
const cluster = `${process.env.PUBLIC_URL}/img/Cluster.png`;
const corridorband = `${process.env.PUBLIC_URL}/img/CorridorBand.png`;
const intrusion = `${process.env.PUBLIC_URL}/img/Intrusion.png`;
const livelinebands = `${process.env.PUBLIC_URL}/img/LiveLineBands.png`;
const lla = `${process.env.PUBLIC_URL}/img/LLA.png`;
const lla4 = `${process.env.PUBLIC_URL}/img/LLA4.png`;
const managementarea = `${process.env.PUBLIC_URL}/img/ManagementArea.png`;
const networkcorridor = `${process.env.PUBLIC_URL}/img/NetworkCorridor.png`;
const simpleclearancerules = `${process.env.PUBLIC_URL}/img/SimpleClearanceRules.jpg`;
const simplecorridorrules = `${process.env.PUBLIC_URL}/img/SimpleCorridorRules.jpg`;
const v3a = `${process.env.PUBLIC_URL}/img/V3A.png`;
const v3b = `${process.env.PUBLIC_URL}/img/V3B.png`;
const v3h = `${process.env.PUBLIC_URL}/img/V3H.png`;
const v3r = `${process.env.PUBLIC_URL}/img/V3R.png`;
const v3s = `${process.env.PUBLIC_URL}/img/V3S.png`;

const glossary = [
  {
    "title": "Attachment Height",
    "handle": "8242bdb2-6867-409c-8b3e-efe15168cf53",
    "description": "The height above ground level of the attachment point. It is normally expressed as height in metres or feet.",
    "related": [
      "ff305f3c-3fc5-4f2d-8722-563213aa3ab2"
    ],
    "images": [baymodel],
    "aka": [

    ]
  },
  {
    "title": "Attachment Point",
    "handle": "ff305f3c-3fc5-4f2d-8722-563213aa3ab2",
    "description": "The spatial coordinates of the ROAMES captured conductor attachment to a supporting structure e.g. pole. This is expressed as its height and location.",
    "related": [
      "8242bdb2-6867-409c-8b3e-efe15168cf53"
    ],
    "images": [baymodel],
    "aka": [

    ]
  },
  {
    "title": "Band",
    "handle": "98271e97-65b8-4512-9fee-44ac9bbac07c",
    "description": "An area around a conductor used to describe the proximity of intrusions and allow more precise management of risk, assessment and treatment. Bands can be grouped as one of the following:\n - Live Line Group Bands\n - Network Corridor Bands\n - Clearance Space Bands\n\nModels of different complexity are supported.",
    "related": [
      "e315948b-27bd-4877-803c-bbeb68221d02",
      "272145a7-791d-4bfd-aaf4-a349150c7a62",
      "dceb814a-27f4-487a-b6c0-591f9c707a0e",
      "55546447-3560-43c9-8366-c51eb93e61ed",
      "c97572ad-ac37-4586-9509-dce15caacf6e"
    ],
    "aka": [
      "Vegetation Band",
      "Intrusion Band",
      "Intrusion Zone"
    ]
  },
  {
    "title": "Bay",
    "handle": "da9bdfe6-1d21-484c-8937-6d23959993ca",
    "description": "A Bay is part of the electrical network. It represents all conductors that are supported between two structures such as Poles or Towers. A bay may consist of one or more spans each of which represent a circuit.",
    "related": [

    ],
    "images": [baymodel],
    "aka": [

    ]
  },
  {
    "title": "Captured Conductor Location (CCL)",
    "handle": "e759a050-9479-4c91-a559-8d9ef54bc6f7",
    "description": "Location of the conductor as captured by LiDAR and modelled by ROAMES. All standard intrusions are modelled from this.",
    "related": [
      "dd71b2c4-5bfd-42be-91b7-71732d0431aa"
    ],
    "aka": [

    ]
  },
  {
    "title": "Clearance Band (CL) [Model A]",
    "handle": "2aa15af9-4fe6-4835-9e3f-d570164cb769",
    "description": "An area surrounding conductors used to manage vegetation risk and assist with assessment and treatment. This band represents the area that must always be kept clear.  This is an initial band used in Model A from which other bands are set. It is the equivalent to CL0. Model B (standard) can use V1 for the same purpose.",
    "related": [
      "98271e97-65b8-4512-9fee-44ac9bbac07c",
      "272145a7-791d-4bfd-aaf4-a349150c7a62"
    ],
    "images": [clearancebands],
    "aka": [

    ]
  },
  {
    "title": "Clearance Space Bands",
    "handle": "272145a7-791d-4bfd-aaf4-a349150c7a62",
    "description": "Area surrounding conductors used to manage vegetation risk, and assist with assessment and treatment. Bands represent areas that must be kept clear and those that allow for regrowth. Vegetation is modelled to show intrusions of vegetation as both counts and volume. Two default types of model are supported as follows:\n - Model A - An initial band is set as CL (equivalent to CL0).\n - Model B (Standard) - An initial band is set as V1\n\nThe initial band is usually an area that must be kept clear. The default for other bands is to increment 1.0m from the initial band and represent different allowances for growth. All distances are configurable for clients.\n\n_{please note distances and shapes can be different from the standard models}_",
    "related": [
      "98271e97-65b8-4512-9fee-44ac9bbac07c"
    ],
    "images": [clearancebands, simpleclearancerules],
    "aka": [
      "Clearance Band Intrusions"
    ]
  },
  {
    "title": "Cluster",
    "handle": "7091b621-a35e-4ff2-9d3f-b05c87541dc3",
    "description": "A group of LiDAR points that belong to a single Intrusion Category. Outline can be seen as a 2D shape in ROAMES World.\n\nIntrusion Categories include:\n - Vegetation\n - Structure – generally Building\n -	Vehicle – movable vehicles and trailers (optional feature)",
    "related": [
      "98271e97-65b8-4512-9fee-44ac9bbac07c",
      "c97572ad-ac37-4586-9509-dce15caacf6e",
      "e996774a-df2e-4dd0-922a-2f8477b0865c"
    ],
    "images": [cluster],
    "aka": [
      "Footprint"
    ]
  },
  {
    "title": "Close Proximity Band (CP) [Model A]",
    "handle": "af9bc1fc-7a6b-4168-b84d-b793db143034",
    "description": "Band within potential arcing distance of Captured Conductor Location (CCL). Typically for Model A.",
    "related": [
      "e315948b-27bd-4877-803c-bbeb68221d02"
    ],
    "images": [livelinebands],
    "aka": [

    ]
  },
  {
    "title": "Conductor",
    "handle": "dd71b2c4-5bfd-42be-91b7-71732d0431aa",
    "description": "A wire used to transmit electricity. Single power line across a Bay.",
    "related": [

    ],
    "images": [baymodel],
    "aka": [
      "Power Line",
      "Wire",
      "Catenary"
    ]
  },
  {
    "title": "Edge of Easement (EOE) [Model A]",
    "handle": "af3eea28-06f2-4315-8519-bdc3bf7891a8",
    "description": "Part of the Model A Network Corridor Bands model used to define horizontal distance from the conductor that should be kept clear. It is used as part of the definition for V3A, V3B, V3S and V3R Network Corridor Model A bands. It is generally used in Rural areas and marks the edge of vegetation that will need to be managed before the next cycle of inspection. Outside this area some risks may be managed e.g. Fall-In Risk / V3H.",
    "related": [
      "55546447-3560-43c9-8366-c51eb93e61ed"
    ],
    "images": [networkcorridor],
    "aka": [
      "Corridor Width"
    ]
  },
  {
    "title": "Fall-In Risk [Model B]",
    "handle": "b774e3b8-6ca0-4fff-a6a0-184ee4d53727",
    "description": "Trees in this space have the potential to fall on the conductor and so are treated as a possible fall-in risk hazard. The band covers an area between 0 and 60 degrees from the outer edge of the V1 band of Model A and excludes area covered by Live Line Above to Sky (LLAS). Similar to V3H band of Model A.",
    "related": [
      "98271e97-65b8-4512-9fee-44ac9bbac07c",
      "55546447-3560-43c9-8366-c51eb93e61ed"
    ],
    "images": [simplecorridorrules],
    "aka": [
      "Similar to V3 Hazard (V3H)"
    ]
  },
  {
    "title": "Intrusion",
    "handle": "c97572ad-ac37-4586-9509-dce15caacf6e",
    "description": "A portion of a group of lidar points, which belong to the same cluster, that fall within an area of interest for the electrical network known as a Band. A 3D point is shown as the closest point to the network. It is described as the Intrusion category it belongs to e.g. Vegetation.",
    "related": [
      "98271e97-65b8-4512-9fee-44ac9bbac07c",
      "e996774a-df2e-4dd0-922a-2f8477b0865c",
      "7091b621-a35e-4ff2-9d3f-b05c87541dc3"
    ],
    "images": [intrusion],
    "aka": [

    ]
  },
  {
    "title": "Intrusion Category",
    "handle": "e996774a-df2e-4dd0-922a-2f8477b0865c",
    "description": "Identifies the type of object that can be applied to a cluster. It is used to group Intrusions. Intrusion Categories include:\n - Vegetation\n - Structure – generally Building\n -	Vehicle – movable vehicles and trailers (optional feature)",
    "related": [
      "98271e97-65b8-4512-9fee-44ac9bbac07c",
      "c97572ad-ac37-4586-9509-dce15caacf6e",
      "7091b621-a35e-4ff2-9d3f-b05c87541dc3"
    ],
    "images": [],
    "aka": [

    ]
  },
  {
    "title": "Live Line Group Bands [Model A]",
    "handle": "e315948b-27bd-4877-803c-bbeb68221d02",
    "description": "Grouping of intrusion bands that are either very close to the Captured Conductor Location (CCL) or are directly above them. Treatment may require specialist Live Line techniques. Model B bands can also be grouped as Live Line.\n\nGroup consists of:\n - Close Proximity band (CP)\n - Live Line band (LL)\n - Live Line Above band (LLA)\n - Live Line Above 4m band (LLA4)\n\nNote that LLA and LLA4 can be merged into Live Line Above to Sky band (LLAS) as per Model B. These can also be considered as part of the Network Corridor bands.",
    "related": [
      "af9bc1fc-7a6b-4168-b84d-b793db143034",
      "6d79ec8d-cf2d-4054-934f-e9d11020ae2d",
      "53e492d8-be1e-4321-8029-86f43ae955f7",
      "a92ed63a-587b-40c6-82d9-207d547c552c"
    ],
    "images": [livelinebands],
    "aka": [

    ]
  },
  {
    "title": "Live Line Band (LL) [Model A]",
    "handle": "6d79ec8d-cf2d-4054-934f-e9d11020ae2d",
    "description": "Band within Proximity to Captured Conductor Location (CCL) which is below the height of the lowest Captured Conductor Location (CCL) and requires specialist Live Line techniques to treat.",
    "related": [
      "e315948b-27bd-4877-803c-bbeb68221d02"
    ],
    "images": [livelinebands],
    "aka": [

    ]
  },
  {
    "title": "Live Line Above Band (LLA) [Model A]",
    "handle": "53e492d8-be1e-4321-8029-86f43ae955f7",
    "description": "Covers the area above conductor height of the lowest conductor, but less than 4.0m above the highest conductor. Requires specialist Live Line techniques to treat. This is a Network Corridor band for Model A that can have its intrusions reported in a Live Line grouping for ROAMES Analytics.",
    "related": [
      "e759a050-9479-4c91-a559-8d9ef54bc6f7",
      "98271e97-65b8-4512-9fee-44ac9bbac07c",
      "931c12b1-83f2-4cb0-9e00-a8ca6d581b1b",
      "55546447-3560-43c9-8366-c51eb93e61ed",
      "e315948b-27bd-4877-803c-bbeb68221d02"
    ],
    "images": [lla],
    "aka": [

    ]
  },
  {
    "title": "Live Line Above 4m Band (LLA4) [Model A]",
    "handle": "a92ed63a-587b-40c6-82d9-207d547c552c",
    "description": "Covers the area above conductor height of the lowest conductor, but more than 4.0m above the highest conductor. Requires specialist Live Line techniques to treat. This is a Network Corridor band for Model A that can have its intrusions reported in a Live Line grouping for ROAMES Analytics.",
    "related": [
      "e759a050-9479-4c91-a559-8d9ef54bc6f7",
      "98271e97-65b8-4512-9fee-44ac9bbac07c",
      "931c12b1-83f2-4cb0-9e00-a8ca6d581b1b",
      "55546447-3560-43c9-8366-c51eb93e61ed",
      "e315948b-27bd-4877-803c-bbeb68221d02"
    ],
    
    "images": [lla4],
    "aka": [

    ]
  },
  {
    "title": "Live Line Above to Sky Band (LLAS) [Model B]",
    "handle": "2b66c900-9f6a-418e-8db2-5d87127efa6c",
    "description": "Covers the area above conductor height of the lowest conductor. Requires specialist Live Line techniques to treat and is intended to alert operators to vegetation that may come into contact with the network were it to fall. This is normally used in model B. It is the equivalent to LLA and LLA4 in model A.",
    "related": [
      "98271e97-65b8-4512-9fee-44ac9bbac07c",
      "dceb814a-27f4-487a-b6c0-591f9c707a0e"
    ],
    "images": [simplecorridorrules],
    "aka": [
      "Combines information from the following:",
      "Live Line Above Band (LLA)",
      "Live Line Above 4m (LLA4)"
    ]
  },
  {
    "title": "Management Area (MA)",
    "handle": "ccd4f47e-bb12-4035-9ad6-3c20863d96a1",
    "description": "A geographic area used to manage vegetation or network risk treatments. Can be used to provide the extent of work to be assigned e.g. for vegetation contractors.",
    "related": [

    ],
    "images": [managementarea],
    "aka": [
      "Vegetation Zone"
    ]
  },
  {
    "title": "Network Corridor",
    "handle": "931c12b1-83f2-4cb0-9e00-a8ca6d581b1b",
    "description": "An easement or corridor for transmitting, distributing, or supplying electricity. Generally in a rural area supporting different vegetation treatment options to urban areas.  An Edge of Easement denotes a boundary beyond which longer term vegetation risks can be managed.",
    "related": [
      "55546447-3560-43c9-8366-c51eb93e61ed",
      "dceb814a-27f4-487a-b6c0-591f9c707a0e",
      "af3eea28-06f2-4315-8519-bdc3bf7891a8"
    ],
    "images": [networkcorridor],
    "aka": [

    ]
  },
  {
    "title": "Network Corridor Bands – Model A",
    "handle": "55546447-3560-43c9-8366-c51eb93e61ed",
    "description": "Model A consists of areas around the conductor used to describe vegetation intrusions. Each area can provide information on risks or assist in different treatment options. Much of the model is based on the setting of a clearance distance known as the Edge of Easement (EOE). Information can be in the form of intrusion counts or intrusion volume. Generally used in a rural area.\n\nThis is a more complex model than the standard Network Corridor Model B.\n\n_{please note distances, angles and shapes can be different from the standard model}_",
    "related": [
      "e759a050-9479-4c91-a559-8d9ef54bc6f7",
      "98271e97-65b8-4512-9fee-44ac9bbac07c",
      "931c12b1-83f2-4cb0-9e00-a8ca6d581b1b",
      "dceb814a-27f4-487a-b6c0-591f9c707a0e",
      "af3eea28-06f2-4315-8519-bdc3bf7891a8"
    ],
    "images": [corridorband],
    "aka": [

    ]
  },
  {
    "title": "Network Corridor Bands – Model B (Standard)",
    "handle": "dceb814a-27f4-487a-b6c0-591f9c707a0e",
    "description": "Model B is the Default model which is used to show potential risks above and around the network. Network Corridor Bands provide an alternative view to Clearance bands applying to spans of any length. Information can be in the form of intrusion counts or intrusion volume. Generally used in a rural area.\n\nThis network corridor clearance is intended to alert operators to vegetation that may come in contact with the network if it were to fall.\n\n_{please note distances, angles and shapes can be different from the standard model}_",
    "related": [
      "931c12b1-83f2-4cb0-9e00-a8ca6d581b1b",
      "55546447-3560-43c9-8366-c51eb93e61ed"
    ],
    "images": [simplecorridorrules],
    "aka": [

    ]
  },
  {
    "title": "Site",
    "handle": "1909ac6a-b93f-4b1b-911e-bef0600c72ca",
    "description": "A Position on the ground where an object of interest occurs e.g. a Pole Site is the position on the ground of a pole.",
    "related": [

    ],
    "images": [baymodel],
    "aka": [
      "Pole Site"
    ]
  },
  {
    "title": "Span",
    "handle": "8d6bb15b-a118-4ca0-bfd9-0027970c747f",
    "description": "A span is a grouping of one or more conductors within a bay that carry a single circuit (feeder).",
    "related": [

    ],
    "images": [baymodel],
    "aka": [

    ]
  },
  {
    "title": "V3 Above Band (V3A) [Model A]",
    "handle": "77aa2dd5-7589-4d62-9b83-2abc06d44fa0",
    "description": "Covers the area inside the Edge Of Easement (EOE) above conductor height of the lowest conductor, but less than 4.0m above the highest conductor.  Excludes area covered by Live Line Above (LLA) band.",
    "related": [
      "98271e97-65b8-4512-9fee-44ac9bbac07c",
      "931c12b1-83f2-4cb0-9e00-a8ca6d581b1b",
      "55546447-3560-43c9-8366-c51eb93e61ed",
      "af3eea28-06f2-4315-8519-bdc3bf7891a8"
    ],
    "images": [v3a],
    "aka": [

    ]
  },
  {
    "title": "V3 Below Band (V3B) [Model A]",
    "handle": "6036f353-5834-4707-98e3-75cd8243c2c4",
    "description": "Main area in Network Corridor model A for vegetation treatment. Covers an area inside the Edge of Easement (EOE) below lowest Captured Conductor Location (CCL) and above the V3 Scrub (V3S), which is 2.0m above the ground.",
    "related": [
      "98271e97-65b8-4512-9fee-44ac9bbac07c",
      "931c12b1-83f2-4cb0-9e00-a8ca6d581b1b",
      "55546447-3560-43c9-8366-c51eb93e61ed",
      "af3eea28-06f2-4315-8519-bdc3bf7891a8"
    ],
    "images": [v3b],
    "aka": [

    ]
  },
  {
    "title": "V3 Hazard Band (V3H) [Model A]",
    "handle": "645fe349-b681-4d4f-a90d-c95158aedc23",
    "description": "Trees in this space have the potential to fall on the conductor and so are treated as a possible fall-in risk hazard.  The band covers an area between 0 and 60 degrees from the outer edge of the Clearance Band (CL), of the outermost Captured Conductor Locations (CCLs), on the lowest Span in a Bay. Excludes area covered by Live Line Above (LLA), Live Line Above 4m (LLA4) and V3 Above (V3A).",
    "related": [
      "98271e97-65b8-4512-9fee-44ac9bbac07c",
      "931c12b1-83f2-4cb0-9e00-a8ca6d581b1b",
      "55546447-3560-43c9-8366-c51eb93e61ed",
      "af3eea28-06f2-4315-8519-bdc3bf7891a8"
    ],
    
    "images": [v3h],
    "aka": [
      "Fall In Risk"
    ]
  },
  {
    "title": "V3 Regrowth Band (V3R) [Model A]",
    "handle": "c1a7b112-8e23-4103-ad82-dc6dc6b6b596",
    "description": "Vegetation up to 5.0m from the Edge Of Easement (EOE) is identified so that it can be assessed as to how likely it is to grow into the clearance space during the cycle time. Its aim is to assist in identifying vegetation e.g. trees that can be treated early to avoid later more expensive work. Band is below the V3 Hazard Band (V3H).",
    "related": [
      "98271e97-65b8-4512-9fee-44ac9bbac07c",
      "931c12b1-83f2-4cb0-9e00-a8ca6d581b1b",
      "55546447-3560-43c9-8366-c51eb93e61ed",
      "af3eea28-06f2-4315-8519-bdc3bf7891a8"
    ],
    "images": [v3r],
    "aka": [

    ]
  },
  {
    "title": "V3 Scrub Band (V3S) [Model A]",
    "handle": "2739b55b-6319-46ae-a89f-a3969471abc5",
    "description": "Intrusions inside the Edge of Easement (EOE), located 0.5m-2.0m above ground. This identifies low lying scrub that has the potential to grow up to the conductor.",
    "related": [
      "98271e97-65b8-4512-9fee-44ac9bbac07c",
      "931c12b1-83f2-4cb0-9e00-a8ca6d581b1b",
      "55546447-3560-43c9-8366-c51eb93e61ed",
      "af3eea28-06f2-4315-8519-bdc3bf7891a8"
    ],
    "images": [v3s],
    "aka": [

    ]
  }
];

export {
  glossary
}