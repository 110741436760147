import React from "react";
import { Link, Switch } from "react-router-dom";
import { Routes } from "../routing/Routes";
import { ContactUs } from "./support/ContactUs";
import { Glossary } from "./support/Glossary";
import { GetStarted } from "./support/GetStarted";
import { QuickStartGuideForRoamesWorld } from "./support/QuickStartGuideForRoamesWorld";
import { QuickStartGuideForAnalytics } from "./support/QuickStartGuideForAnalytics";
import { ProtectedRoute } from "../routing/ProtectedRoute";
import { NotFound } from "./NotFound";
import QuickstartAnalytics from '../../../../data/ROAMES Analytics - Quick Start Guide.pdf';
import QuickstartRoamesWorld from '../../../../data/ROAMES World - Quick Start Guide.pdf';
import { ReleaseNotes } from "./support/ReleaseNotes";
import { ReleaseNotesAppHub } from "./support/ReleaseNotesAppHub";
import { ReleaseNotesAnalytics } from "./support/ReleaseNotesAnalytics";
import { ReleaseNotesRoamesWorld } from "./support/ReleaseNotesRoamesWorld";

interface SupportTileProps {
  icon: string;
  text: string;
  href: string;
}

const SupportTile = ({ href, text, icon }: SupportTileProps) => {
  return (
    <Link
      key={text}
      className="tile"
      to={{
        pathname: href,
      }}
    >
      <div className={`tile__icon icon icon-${icon}`} />
      <span className="tile__text">{text}</span>
    </Link>
  );
};

interface PreviousRoute {
  previousPath: string;
  previousPathTitle: string;
}

const suportPreviousRoutes: PreviousRoute[] = [
  {
    previousPath: Routes.SUPPORT,
    previousPathTitle: "Support",
  },
];

const glossaryPreviousRoutes: PreviousRoute[] = [
  {
    previousPath: Routes.SUPPORT,
    previousPathTitle: "Support",
  },
  {
    previousPath: Routes.SUPPORT_GETSTARTED,
    previousPathTitle: "Get Started",
  },
];

const releaseNotesPreviousRoutes: PreviousRoute[] = [
  {
    previousPath: Routes.SUPPORT,
    previousPathTitle: "Support",
  },
  {
    previousPath: Routes.SUPPORT_RELEASENOTES,
    previousPathTitle: "Release Notes",
  },
];

const PreviousPageNavigation = (states: PreviousRoute[], location: string) => {
  if (states !== undefined) {
    const previousRoutes = states.map(
      (previousRoute: PreviousRoute, index: number) => (
        <div key={index} className="navigation-link">
          <Link
            className="navigation-link-clickable"
            to={{
              pathname: previousRoute.previousPath,
            }}
          >
            {previousRoute.previousPathTitle}
          </Link>
          <div className="navigation-spacer">{">"}</div>
        </div>
      )
    );
    return (
      <div className="navigation-directory">
        <div className="navigation-list">
          {previousRoutes} {location}
        </div>
      </div>
    );
  }
};

interface SupportSection {
  icon: string;
  text: string;
  href: string;
  displayTile: boolean;
  previousRoutes: PreviousRoute[];
  pdfLink?: string;
  pdfContent?: any;
  content: () => JSX.Element;
}

const supportSections = [
  {
    icon: "flight_takeoff",
    text: "Get Started",
    href: Routes.SUPPORT_GETSTARTED,
    displayTile: true,
    previousRoutes: suportPreviousRoutes,
    content: GetStarted
  },
  {
    icon: "contact_mail",
    text: "Contact Us",
    href: Routes.SUPPORT_CONTACTUS,
    displayTile: true,
    previousRoutes: suportPreviousRoutes,
    content: ContactUs
  },
  {
    icon: "assignment",
    text: "Release Notes",
    href: Routes.SUPPORT_RELEASENOTES,
    displayTile: true,
    previousRoutes: suportPreviousRoutes,
    content: ReleaseNotes
  },
  {
    icon: "menu_book",
    text: "Glossary",
    href: Routes.SUPPORT_GLOSSARY,
    displayTile: true,
    previousRoutes: suportPreviousRoutes,
    content: Glossary
  },
  {
    icon: "",
    text: "Analytics Quick Start Guide",
    href: Routes.SUPPORT_GETSTARTED_ANALYTICS,
    displayTile: false,
    previousRoutes: glossaryPreviousRoutes,
    pdfLink: 'ROAMES Analytics - Quick Start Guide.pdf',
    pdfContent: QuickstartAnalytics,
    content: QuickStartGuideForAnalytics
  },
  {
    icon: "",
    text: "ROAMES World Quick Start Guide",
    href: Routes.SUPPORT_GETSTARTED_WORLD,
    displayTile: false,
    previousRoutes: glossaryPreviousRoutes,
    pdfLink: 'ROAMES World - Quick Start Guide.pdf',
    pdfContent: QuickstartRoamesWorld,
    content: QuickStartGuideForRoamesWorld
  },
  {
    icon: "",
    text: "ROAMES App Hub Release Notes",
    href: Routes.SUPPORT_RELEASENOTES_APPHUB,
    displayTile: false,
    previousRoutes: releaseNotesPreviousRoutes,
    content: ReleaseNotesAppHub
  },
  {
    icon: "",
    text: "ROAMES Analytics Release Notes",
    href: Routes.SUPPORT_RELEASENOTES_ANALYTICS,
    displayTile: false,
    previousRoutes: releaseNotesPreviousRoutes,
    content: ReleaseNotesAnalytics
  },
  {
    icon: "",
    text: "ROAMES World Release Notes",
    href: Routes.SUPPORT_RELEASENOTES_WORLD,
    displayTile: false,
    previousRoutes: releaseNotesPreviousRoutes,
    content: ReleaseNotesRoamesWorld
  }
];

interface SupportProps {
  roles: string[];
}

const Support = ({roles}: SupportProps) => {
  return (
    <div className={"app-content support"}>
      <Switch>
        <ProtectedRoute path={Routes.SUPPORT} exact={true} roles={roles}>
          <div className="support-menu">
            <div className="gradient">
              <h1 className="title">How can we help?</h1>
              <div className="tiles">
                {supportSections
                  .filter((section: SupportSection) => {
                    return section.displayTile;
                  })
                  .map((section: SupportSection, index: number) => {
                    return (
                      <SupportTile
                        key={index}
                        href={section.href}
                        text={section.text}
                        icon={section.icon}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </ProtectedRoute>
        {supportSections.map((section: SupportSection, index: number) => {
          const Content = section.content;
          return (
            <ProtectedRoute key={index} path={section.href} exact={true} roles={roles}>
              <>
                <div className='top-menu'>
                  {PreviousPageNavigation(section.previousRoutes, section.text)}
                  {section.pdfContent ? <a href={section.pdfContent} download={section.pdfLink} className='downloadlink'><span className="icon icon-picture_as_pdf"></span> <span>Save as PDF</span></a> : <></>}
                </div>
                <Content />
              </>
            </ProtectedRoute>
          );
        })}
        <ProtectedRoute path="*" exact={false} roles={roles}>
          <NotFound></NotFound>
        </ProtectedRoute>
      </Switch>
    </div>
  );
};

export { Support };
