import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routing/Routes";

const ReleaseNotesSwitcher = ({value}) => {
  const history = useHistory();
  const onChange = (e) => {
    history.push(`${Routes.SUPPORT_RELEASENOTES}/${e.target.value}`)
  };
  return <select className='switcher' onChange={onChange} value={value}>
  <option value=''>Select Product</option>
  <option value='apphub'>ROAMES App Hub</option>
  <option value='analytics'>ROAMES Analytics</option>
  <option value='world'>ROAMES World</option>
</select>
}

export {
  ReleaseNotesSwitcher
}