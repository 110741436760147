import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_APPLICATION_ID,
    authority: process.env.REACT_APP_DIRECTORY_ID_URL,
    redirectUri: process.env.REACT_APP_CALLBACK_URL,
    postLogoutRedirectUri: process.env.REACT_APP_CALLBACK_URL
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ["User.Read"]
};

export const externalRequest: PopupRequest = {
  scopes: [`api://${process.env.REACT_APP_APPLICATION_ID}/claims`]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};